import React from "react";
import './our-innovation-team.scss';
import Grid from '@material-ui/core/Grid';
import RPAImage from './../../../assets/rpa-image.png';
import DataSecurity from './../../../assets/data-security.png';
import APIImage from './../../../assets/api-image.png';
import expertise_icon from '../../../assets/unrelenting-icon.png';
import Container from '@material-ui/core/Container';

export default function OurInnovationTeam() {
  return (
    <>
      <Container maxWidth="xl" className='our-innovation-team'>
        {/* RPA Section Start */}
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='left-align'>
          <Grid item xl={6} lg={6} md={8} sm={8} xs={12}>
            <h2 className="title"><img src={expertise_icon} alt="expertise icon" className='arrow-img' />and, more from our innovation team</h2>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h3>RPA</h3>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='row-section'>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
              <p className='description'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
              <p className='description'>To support our strategic efforts in Diversity, Equity & Inclusion, we provide a formal mentoring program each year to empower employees of color to grow their careers and increase both the lateral and upward mobility of underrepresented talent, as well as annual Unconscious Bias training for all employees.</p>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              <div className='img-box'>
                <img src={RPAImage} alt="Image" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* RPA Section End */}

        {/* Data Security Start */}
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='right-align'>
          <Grid item xl={6} lg={5} md={12} sm={12} xs={12} className='empty-div'>&nbsp;</Grid>
          <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
            <h3>data security</h3>
          </Grid>
          <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
            <div className='img-box'>
              <img src={DataSecurity} alt="Image" />
              <div className='dot-matrixs'></div>
            </div>
          </Grid>
          <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
            <p className='description'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
          </Grid>
        </Grid>
        {/* Data Security End */}

        {/* API Section Start */}
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='left-align'>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h3>API</h3>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='row-section'>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
              <p className='description'>We develop every day. Striving for better is part and parcel of working at Anywhere – and we’ve got the tools to help you achieve anything you set your mind to. Our development philosophy is anchored to supporting a high performing, inclusive, and connected culture with learning you can access from anywhere. As well as a best in class on-boarding and new hire program, we provide access to on-demand learning with free LinkedIn Learning accounts, RealU with resources to inspired you to ‘own your future’. We are also proud to offer targeted leadership development programs for aspiring leaders, people leaders, and sales leaders driving top-line growth. We leverage our industry-leading executive development experience, Ascend, to prepare identified leaders for next level roles.</p>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              <div className='img-box'>
                <img src={APIImage} alt="Image" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* API Section End */}
      </Container>
    </>
  )
}


