import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Link } from "gatsby";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import plus_icon from '../../../assets/Plus-Icon86.png';
import './our-proud-execution.scss';
import movepro360_img from '../../../assets/movepro360.png';
import global_service_quote_img from '../../../assets/blue quote.png';
import Container from '@material-ui/core/Container';

export default function OurProudExecution() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Container maxWidth="xl" className='technology-our-key'>
        <Grid container>
          <Grid item container xl={6} lg={6} md={6} sm={7} xs={7}>
            <h2 className="heading"><img src={plus_icon} alt="plus icon" className='plus-img' />our proud execution</h2>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="txt-with-blue-section">
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} className='rich-text-div'>
              <Box sx={{ width: '100%', }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons={true}
                      aria-label="scrollable prevent tabs example"
                      className='ourkey-tabs'>
                      <Tab label="movepro360" value="1" />
                      <Tab label="moveazy" value="2" />
                      <Tab label="flexible mobility" value="3" />
                      <Tab label="DWS" value="4" />
                      <Tab label="benefits builder" value="5" />
                      <Tab label="other" value="6" />
                      <Tab label="for scroll" value="7" />
                    </Tabs>
                  </Box>
                  <TabPanel value="1" className='tab-panel'>
                    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='tab-details'>
                      <Grid container item xl={7} lg={7} md={11} sm={12} xs={12} className='ourkey-tabcontext'>
                        <Grid item xl={9} lg={9} md={12} sm={12} xs={12} className='keytab-content'>
                          <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu
                            urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur 
                            tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse 
                            consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor 
                            sagittis placerat. sed vitae libero vestibulum <a href="#">Benefits Builder</a></p>
                          <Button className='button-tab'>learn more</Button>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className='ourkey-imgtab'>
                            <img src={movepro360_img} alt="img" className='ourkey-img' />
                          <Link href="#" className='img-subtitle'>Download</Link>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xl={5} lg={5} md={12} sm={12} xs={12} className="right-author-div">
                        <img src={global_service_quote_img} alt="Quote icon" />
                        <p>
                          Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risused <Link to='#'>movepro 360</Link> magna.
                        </p>
                        <p className="author-name">LAURA BERRINGTON</p>
                        <p className="designation">Technology Officer</p>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2" className='tab-panel'>
                    <div className='tab-cust-div'><h2>Tab 2</h2></div>
                  </TabPanel>
                  <TabPanel value="3" className='tab-panel'>
                    <div className='tab-cust-div'><h2>Tab 3</h2></div>
                  </TabPanel>
                  <TabPanel value="4" className='tab-panel'>
                    <div className='tab-cust-div'><h2>Tab 4</h2></div>
                  </TabPanel>
                  <TabPanel value="5" className='tab-panel'>
                    <div className='tab-cust-div'><h2>Tab 5</h2></div>
                  </TabPanel>
                  <TabPanel value="6" className='tab-panel'>
                    <div className='tab-cust-div'><h2>Tab 6</h2></div>
                  </TabPanel>
                  <TabPanel value="7" className='tab-panel'>
                    <div className='tab-cust-div'><h2>Tab 7</h2></div>
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}


