import React from "react";
import './lets-talk.scss';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export default function LetsTalk() {
  return (
    <>
      <div className="lets-talk-cls">
        <div className="letstalk-block">
          <div className="overlay">
            <Container maxWidth="xl" className="letsTalk-container">
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} >
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="letsTalk-details">
                    <Grid item container xl={4} lg={5} md={6} sm={6} xs={8} >
                      <Typography className="title">connect today with us to build meaningful employee experiences</Typography>
                      <Button variant="contained" className="btn-more">let's talk</Button>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </>
  )
}


