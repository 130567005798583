import React from 'react'
import Layout from '../../staticcomponents/layout'
import './technology.scss'
import TechHeader from './technology-header'
import OurProudExecution from './our-proud-execution'
import OurInnovationTeam from './our-innovation-team'
import LetsTalk from '../../staticcomponents/lets-talk'

export default function Technology() {
    return (
        <>
            <Layout>
                <div className='technology-landing-main'>
                    <TechHeader />
                    <div className='donuts-left'>
                        <OurProudExecution />
                        <div className='capsules-bg'>
                            <OurInnovationTeam />
                        </div>
                    </div>
                    <LetsTalk />
                </div>
            </Layout>
        </>
    )
}