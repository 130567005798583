import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import tech_video_thumbnail from '../../../assets/tech-solution-video.png';
import './technology-header.scss';
import Container from '@material-ui/core/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function TechHeader() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className='tech-landing-hero'>
        {/* <grid for image background> */}
        <Grid className='header-div'>
          <Grid container className='header-container'>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className='header-hero-bg'>
              <div className='blue-round-div'>
                <h1 className='title'>technology</h1>
                <p className='blue-sec-desp'>whether you’re designing your first mobility program or relocating thousands of executives people each year, cartus delivers proactive dynamic</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* <about us solutions section> */}
      <Container maxWidth="xl" className='zero-padding-div'>
        <Grid container className='tech-landing-txt-with-video-container'>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='header-intro-div'>
            <Grid item xs={6} sm={5} md={5} lg={7} xl={6}>
              <h2 className='title'>our innovations and leap of faith.</h2>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                <h3 className='sub-title'>act on your business transformation now</h3>
                <p className='description'>Innovating ways to optimize, anticipate, and manage the relocation lifecycle, Cartus provides your mobility managers, domestic transferees, and international assignees with the best experience possible.</p>
                <p className='description'>We deliver a holistic client and employee relocation journey through our rapid, flexible technology solutions, which we design and build based on real user feedback following the key tenets of the Agile process. Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>
                <p className='description'>Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id.</p>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
                <div className='image-box'>
                  <Paper elevation={0} className="video-content">
                    <Button className="button-container" onClick={handleClickOpen}>
                      <img src={tech_video_thumbnail}
                        alt="video"
                        className="video-img" />
                      <PlayCircleIcon fontSize="large" className="play-button-icon" />
                    </Button>
                    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                      <Box className="popup-overlay">
                        <IconButton className="popup-close-icon" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                        <DialogContent className='cust-dialog'>
                          <Grid container className='dialog-content'>
                            <div className="iframe-class">
                              <iframe
                                width="560"
                                height="349"
                                className="responsive-iframe"
                                src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                            </div>
                          </Grid>
                        </DialogContent>
                      </Box>
                    </Dialog>
                    <Grid>
                      <Paper elevation={0}>
                        <Typography className="video-title">our solutions</Typography>
                        <Typography className="video-subtitle">how cartus helped magnolia sheia</Typography>
                      </Paper>
                    </Grid>

                  </Paper>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}


